<template>
  <div id="mangeGroupPdfsCard" class="w-100">
    <v-snackbar color="primary" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <!-- Delete pdf dialog -->
    <v-dialog v-model="delPDFDialog" max-width="390" persistent>
      <v-card :loading="loading || superLoading">
        <v-card-title class="headline"> Please confirm </v-card-title>

        <v-card-text>
          Are you sure you want to delete the selected pdf ?
          <b class="text-capitalize">'{{ selectedPDF.pdfName }}'</b> ?
          <br /><br />
          This action cannot be undone.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            :disabled="loading || superLoading"
            dark
            @click.prevent="deletePDF(selectedPDF)"
          >
            Delete
          </v-btn>

          <v-btn
            color="primary"
            :disabled="loading || superLoading"
            text
            @click="delPDFDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card :loading="loading || superLoading" class="w-100 pb-4 px-4">
      <!-- Title -->
      <h6 class="text-h6 pt-4">
        <v-icon class="mr-1">mdi-chevron-right</v-icon> Edit PDFs:
      </h6>

      <!-- Fetch PDF Select -->
      <v-container>
        <v-row align="center" no-gutters v-if="error">
          <v-col cols="12">
            <v-alert type="error">{{ error }}</v-alert>
          </v-col>
        </v-row>

        <v-row align="center" no-gutters>
          <!-- Input Row w/ Button -->
          <v-col cols="12" sm="10" class="mx-auto">
            <div>
              <v-select
                v-model="selectedFolder"
                @change="initFetchPdfs()"
                :items="selectedGroupObj.folders"
                :disabled="loading"
                label="Select Folder"
                item-text="text"
                item-value="value"
                dense
                outlined
              ></v-select>
            </div>
          </v-col>
        </v-row>
        <v-divider class="mb-1 mt-5"></v-divider>
      </v-container>

      <!-- Display Pdfs List -->
      <v-container>
        <v-row v-if="pdfs.length > 0" align="center" justify="center">
          <!-- Pdfs List -->
          <v-col cols="12" sm="12" md="7">
            <v-list-item v-for="(pdfObj, i) in pdfs" :key="i">
              <v-list-item-avatar class="mr-2 mr-md-4">
                <v-icon class="indigo lighten-1" color="white">
                  mdi-file
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <span class="mr-2 text-transform-capitalize">
                    {{ pdfObj.pdfName }}
                  </span>
                </v-list-item-title>

                <v-list-item-subtitle>
                  Uploaded: {{ utcToDateText(pdfObj.id) }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn @click="openDeleteModal(pdfObj)" outlined small>
                  <v-icon class="ml-1" size="18">mdi-trashcan</v-icon>
                  Delete
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-col>

          <!-- Navigation Buttons Row -->
          <v-col cols="12" sm="12" class="d-flex justify-center">
            <v-btn
              class="mx-1"
              color="primary darken-2"
              :loading="loading"
              @click="initFetchPdfs('prev')"
              text
            >
              <v-icon>mdi-chevron-left</v-icon> Previous
            </v-btn>
            <v-btn
              class="mx-1"
              color="primary darken-2"
              :loading="loading"
              :disabled="pdfs.length < 10"
              @click="initFetchPdfs('next')"
              text
            >
              Next
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
var dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "MangeGroupPdfsCard",
  props: ["superLoading", "selectedGroupObj"],
  data: () => ({
    loading: true,
    snackbar: false,
    snackbarText: "",
    error: "",
    delPDFDialog: false,
    selectedPDF: "",
    selectedFolder: "",
    pdfs: [],
    selectedPdfPayload: null,
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    utcToDateText(utc) {
      return dayjs.utc(utc * 1000).format("DD-MM-YY");
    },

    initFetchPdfs(requestType) {
      const selectedPdfPayload = {
        folder: this.selectedFolder,
        group: this.selectedGroupObj.name,
        requestType,
      };

      this.selectedPdfPayload = selectedPdfPayload;

      this.fetchPdfs(selectedPdfPayload);
    },

    fetchPdfs(selectedPdfPayload) {
      this.setLoading(true);

      // { folder, group, requestType: null }
      var payload = selectedPdfPayload;

      // do not get prev or next if diffrent group/folder
      if (
        this.selectedPdfPayload == null ||
        payload.folder != this.selectedPdfPayload.folder ||
        payload.group != this.selectedPdfPayload.group
      ) {
        payload.requestType = null;
        this.firstAndLastVisible = null;
      }

      this.selectedPdfPayload = selectedPdfPayload;

      switch (payload.requestType) {
        case "prev":
          payload.doc = this.firstAndLastVisible.firstVisible;
          break;
        case "next":
          payload.doc = this.firstAndLastVisible.lastVisible;
          break;
        default:
          payload.requestType = null;
      }

      this.$store
        .dispatch("getpdfs", payload)
        .then((res) => {
          // write data if there is data
          if (res.data.length > 0) {
            this.snackbarText = "PDFs fetched successfully :)";
            this.pdfs = res.data;
            this.firstAndLastVisible = res.firstAndLastVisible;
          } else {
            //if there is no data show on snackbar accordingly
            switch (payload.requestType) {
              case "prev":
                this.snackbarText = "This is the first page.";
                break;
              case "next":
                this.snackbarText = "This is the last page.";
                break;
              default:
                this.snackbarText = "No PDFs in this folder yet :(";
                this.allQuestions = [];
            }
          }
        })
        .catch((error) => {
          this.error = error;
          this.snackbarText = "Error, please try again :(";
        })
        .finally(() => {
          this.snackbar = true;
          this.setLoading(false);
        });
    },

    openDeleteModal(pdfObj) {
      this.selectedPDF = pdfObj;
      this.delPDFDialog = true;
    },

    deletePDF(selectedPDF) {
      this.setLoading(true);

      this.$store
        .dispatch("deletePDF", selectedPDF)
        .then(() => {
          this.snackbarText = "Pdf deleted successfully :)";
        })
        .catch((err) => {
          this.error = err;
          this.snackbarText = "Error! Please try again :(";
        })
        .finally(() => {
          this.setLoading(false);
          this.snackbar = true;
          this.delPDFDialog = false;
        });
    },
  },
  mounted() {
    this.setLoading(false);
  },
};
</script>
