<template>
  <div id="managepdfgroup" class="grey lighten-3 fill-height">
    <v-container>
      <!-- title -->
      <v-row justify="center">
        <h1 class="text-title my-9">
          <v-icon class="text-h3 mr-2" color="pink darken-1">
            mdi-file-multiple
          </v-icon>
          Manage PDF Group
        </h1>
      </v-row>

      <v-row
        v-if="groupIndex && pdfGroups.length > 0"
        justify="center"
        class="my-4"
      >
        <MangeGroupPdfsCard
          :superLoading="superLoading"
          :selectedGroupObj="pdfGroups[groupIndex]"
          @setsuperLoading="setSuperLoading"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MangeGroupPdfsCard from "@/components/Home/Manage/Pdfs/MangeGroupPdfsCard.vue";
export default {
  name: "ManagePdfGroup",
  metaInfo: {
    title: "Manage PDF Group",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "PDF Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
  components: { MangeGroupPdfsCard },
  data: () => ({
    superLoading: true,
    groupIndex: null,
    pdfGroups: [],
  }),
  methods: {
    setSuperLoading(value) {
      this.superLoading = value;
    },

    fetchPdfGroups() {
      this.setSuperLoading(true);
      // get pdfs from store
      const pdfs = this.$store.getters.pdfGroups;

      //if not found in store
      if (pdfs.length == 0) {
        //get folder names from server
        this.$store
          .dispatch("getPdfDoc")
          .then((res) => {
            if (res) {
              this.pdfGroups = res.pdfGroups;
            }
          })
          .catch((err) => {
            this.error = err;
          })
          .finally(() => {
            this.setSuperLoading(false);
          });
      } else {
        // if found in store
        this.pdfGroups = pdfs;
        this.setSuperLoading(false);
      }
    },
  },
  mounted() {
    const groupIndex = this.$route.params.id;
    // check valid courseIndex
    if (isNaN(groupIndex) || groupIndex.toString().length < 1) {
      this.$router.push("/404");
      return;
    }
    this.groupIndex = groupIndex;

    this.fetchPdfGroups();
  },
};
</script>